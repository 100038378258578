App.BaseModel = Backbone.Model.extend({
  set: (key, val, options) ->
    attr = undefined
    if key == null
      return this
    if typeof key == 'object'
      attrs = key
      options = val
    else
      (attrs = {})[key] = val
    @unsaved = _.extend(@unsaved or {}, attrs)
    Backbone.Model::set.call this, key, val, options
})

loadJS = (FILE_URL, callback) ->
  se = document.createElement('script')
  se.setAttribute 'src', FILE_URL
  se.setAttribute 'type', 'text/javascript'
  se.setAttribute 'defer', true
  document.body.appendChild se
  if typeof callback == 'function'
    se.addEventListener 'load', ->
      callback()
      return
  return

loadCSS = (FILE_URL) ->
  le = document.createElement('link')
  le.setAttribute 'rel', 'stylesheet'
  le.setAttribute 'type', 'text/css'
  le.setAttribute 'href', FILE_URL
  document.getElementsByTagName('head')[0].appendChild le
  return

createCookie = (name, value, days) ->
  expires = undefined
  if days
    date = new Date
    date.setTime date.getTime() + days * 24 * 60 * 60 * 1000
    expires = '; expires=' + date.toGMTString()
  else
    expires = ''
  document.cookie = encodeURIComponent(name) + \
  '=' + encodeURIComponent(value) + expires + '; path=/'
  return

readCookie = (name) ->
  nameEQ = encodeURIComponent(name) + '='
  ca = document.cookie.split(';')
  i = 0
  while i < ca.length
    c = ca[i]
    while c.charAt(0) == ' '
      c = c.substring(1, c.length)
    if c.indexOf(nameEQ) == 0
      return decodeURIComponent(c.substring(nameEQ.length, c.length))
    i++
  null

activateSubmenu = (row) ->
  id = $(row).find('a').attr('data-id')
  s = $(id)
  s.show()
  return

deactivateSubmenu = (row) ->
  id = $(row).find('a').attr('data-id')
  s = $(id)
  s.hide()
  return

exitMenu = () ->
  if navigator.userAgent.indexOf("Chrome") != -1 || navigator.userAgent.indexOf("Safari") != -1
    return !$('.prod-submenu').filter(() -> $(this).is(':hover')).length
  else return $('.prod-submenu').filter(() -> $(this).is(':hover')).length

formatPrice = (value) ->
  value = parseFloat(value).toFixed(2)
  if isNaN(value) || value <= 0
    return '-'
  n = value.split('.')
  n[0] + '<sup><span class="hidden">.</span>' + n[1] + '</sup> lei'
formatNumber = (value) ->
  value = parseFloat(value).toFixed(2)
  n = value.split('.')
  n[0] + '<sup><span class="hidden">.</span>' + n[1] + '</sup>'
formatInt = (value) ->
  value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

validInput = (view, attr, selector) ->
  validInputShow $('[name="' + attr + '"]')

invalidInput = (view, attr, error, selector) ->
  invalidInputShow $('[name="' + attr + '"]'), error

validInputShow = (el) ->
  target = el
  if el.prop('tagName') == 'SELECT'
    parent = el.closest '.select-wrapper'
    if parent.length > 0
      target = parent
  target.removeClass 'error'
  label = $('label[for="' + el.prop('id') + '"]')
  if label.length == 1
    label.removeClass 'error'
  $('div.error-message[data-for="' + el.prop('id') + '"]').remove()
  return

invalidInputShow = (el, error) ->
  target = el
  if el.prop('tagName') == 'SELECT'
    parent = el.closest '.select-wrapper'
    if parent.length > 0
      target = parent
  target.addClass 'error'
  label = $('label[for="' + el.prop('id') + '"]')
  if label.length == 1
    label.addClass 'error'
  msg = $('div.error-message[data-for="' + el.prop('id') + '"]')
  if msg.length == 1
    msg.html error
  else
    target.after '<div class="error-message" data-for="' + el.prop('id') + '">' + error + '</div>'
  return

initResponsiveTable = (tables) ->
  for t in tables
    table = $(t)
    headertext = []
    table.find('th').each (i, el) ->
      text = $(el).text()
      if $(el).hasClass('table-title')
        headertext.push ''
      else
        headertext.push text.replace(/\r?\n|\r/, '')
      return
    table.find('tbody>tr').each ->
      $(this).find('td').each (i, el) ->
        if headertext[i]
          $(el).attr 'data-th', headertext[i]
          $(el).addClass 'with-label'
        else
          $(el).addClass 'row-title'
        return
      return
    return

viewUpdateModel = (evt) ->
  $el = $(evt.currentTarget)
  name = $el.prop('name')
  if !name
    return true
  value = $el.val()
  if $el.prop('type') == 'checkbox'
    value = $el.prop('checked')
  @model.set name, value
  return true

showInLightbox = ($el, config) ->
  html = '<div class="box lightbox"'
  if config.style
    html += ' style="' + config.style + '"'
  html += '>'
  html += '<a href="#" class="close"><svg class="ico">\
  <use href="/icons.svg#ico-cancel-circled"/></svg></a>'
  html += '<div class="lightbox-body"></div>'
  html += '</div>'
  $box = $(html)
  $box.find('.lightbox-body').append $el
  config.destroyOnClose = true
  $box.lightbox_me(config)
  if config.onshow
    setTimeout config.onshow, 400

loadInLightbox = (config) ->
  $.get config.url, (data) ->
    showInLightbox(data, config)

App.openCreateList = (ean13 = null) ->
  if App.is_logged
    url = '/user/new-list'
    if ean13 != null
      url += '/' + ean13
    loadInLightbox({ url: url, centered: true })
    return false
  window.location.href = '/login'
  return false

App.toggleUserVisibility = () ->
  if App.is_logged
    $('.show-no-user').hide()
    $('.show-user').show()
  else
    $('.show-user').hide()
    $('.show-no-user').show()

App.add2list = (listId, ean13) ->
  $.post '/user/list/add', {
    list_id: listId
    ean13: ean13
  }, (json) ->
    trpop.add {
      title: _('Liste Express')
      content: json.message
      type: (if json.success then 'notice' else 'warning') }

App.addToNewsletter = (id, type) ->
  $.post '/add-to-newsletter', {
    id: id, type: type
  }, (json) ->
    trpop.add {
      title: _('Newsletter')
      content: json.message
      type: (if json.success then 'notice' else 'warning') }

App.changeTab = (e) ->
  $el = $(e.currentTarget)
  $active = $el.parent().find('.active')
  $('#' + $active.data('tab')).hide()
  $active.removeClass('active')
  $el.addClass('active')
  $('#' + $el.data('tab')).show()

App.round = (n) ->
  parseFloat(n.toFixed(2))


App.subscribe = () ->
  $('#footer-email-error').hide()
  $('#footer-email').removeClass('error')
  email = $('#footer-email').val()
  if email.match Backbone.Validation.patterns.email
    window.location.href = '/newsletter?email=' + encodeURIComponent(email)
  else
    $('#footer-email-error').show()
    $('#footer-email').addClass('error')

App.scheduleSuggest = (query) ->
  if App.suggest.timer
    clearTimeout(App.suggest.timer)
  App.suggest.query = query
  App.suggest.timer = setTimeout(App.doSuggest, 100)

App.doSuggest = ->
  if App.suggest.xhr
    App.suggest.controller.abort()
  App.suggest.controller = new AbortController()
  signal = App.suggest.controller.signal
  App.suggest.xhr = $.ajax({
    url: '/suggestions/default/' + encodeURIComponent(App.suggest.query)
    signal: signal
    success: (data) ->
      App.suggest.xhr = null
      $ul = $('#search-suggest>ul')
      $ul.empty()
      html = ''
      numberOfCategories = 0
      hrVisible = false
      for i in data.suggestions
        if i instanceof Object
          html += '<li><a href="' + i.url + '">' + i.entry + \
          (if i.baseCategory then '/' + i.baseCategory else '') + '</a></li>'
          numberOfCategories++
        else
          if numberOfCategories > 0 && !hrVisible
            hrVisible = true
            html += '<hr/>'
          html += '<li><a href="/search?q=' + encodeURIComponent(i) + '">' + i + '</a></li>'
      $ul.append(html)
      App.showSuggest()
  })

App.showSuggest = ->
  $(document).on 'click', App.closeSuggest
  $search = $('#search')
  offset = $search.offset()
  $suggest = $('#search-suggest')
  if skel.vars.stateId.indexOf('/xsmall') >= 0
    $sf = $('#search-form')
    width = $sf.outerWidth()
    left = $sf.offset().left
  else
    width = $search.outerWidth() - 1
    left = (offset.left - 1)
  $suggest.css {
    width: width + 'px'
    top: (offset.top + $search.outerHeight() - 1 - $(window).scrollTop()) + 'px'
    left: left + 'px'
    height: 'auto'
    'z-index': 10000 }
  $suggest.show()

App.initCarousel = ($el) ->
  $('.carousel').addClass('active')
  $('.carousel.active').each ->
    App.showOrHideControls($(this))
    return
  App.rightSide = leftSide = ($el) ->
    $c = $el.closest('.carousel')
    $ul = $c.find('ul')
    totalWidth = $ul.outerWidth()
    if($c.hasClass('multi-images'))
      totalWidth = $ul.outerWidth() - 50
    pos = $ul.position()
    width = $c.width()
    left = pos.left
    newLeft = 0
    for i in $ul.find('li')
      $li = $(i)
      w = $li.outerWidth()
      if left + w > width
        break
      left += w
      newLeft -= w
    if -newLeft < totalWidth
      if !$c.hasClass('multi-images') && (totalWidth + newLeft < width)
        difference = totalWidth - width
        newLeft = -difference - 10
      $ul.css({ 'left': newLeft + 'px' })
      left = -newLeft
    if !App.isOnBlog
      if totalWidth + newLeft < width
        $c.find("a.carousel-right").hide()
      else $c.find("a.carousel-right").show()
      $c.find("a.carousel-left").show()
  App.leftSide = rightSide = ($el) ->
    $c = $el.closest('.carousel')
    $ul = $c.find('ul')
    totalWidth = $ul.outerWidth()
    pos = $ul.position()
    width = $c.width()
    left = pos.left
    newLeft = 0
    if left == 0
        return false
    for i in $ul.find('li')
      $li = $(i)
      w = $li.outerWidth()
      if newLeft <= left + width
        break
      newLeft -= w
    $ul.css({ 'left': newLeft + 'px' })
    if !App.isOnBlog
      if newLeft == 0
        $c.find("a.carousel-left").hide()
        $c.find("a.carousel-right").show()
      else
        $c.find("a.carousel-right").show()
        $c.find("a.carousel-left").show()
  $('.carousel').on 'click', '.controls', (e) ->
    $el = $(e.currentTarget)
    if $el.hasClass('carousel-left')
      App.leftSide($el)
    else
      App.rightSide($el)
    false

App.isCarouselTooltipVisible = ($el) ->
  $ul = $el.closest('ul')
  $carousel = $el.closest('.carousel')

  left = $ul.position().left + $el.position().left
  return left >= 0 && left + $el.width() <= $carousel.width()

App.showOrHideControls = (carousel) ->
  productList = carousel.find("ul")
  if carousel.attr('id') == 'image-carousel'
    $width = $('.fixed')
  else
    $width = carousel

  if productList.width() < $width.width()
    carousel.find("a.carousel-left").hide()
    carousel.find("a.carousel-right").hide()
  else
    carousel.find("a.carousel-left").show()
    carousel.find("a.carousel-right").show()
    totalWidth = productList.outerWidth()
    if productList.position().left == 0
      carousel.find("a.carousel-left").hide()
    else
      carousel.find("a.carousel-left").show()

App.closeSuggest = (e) ->
  $(document).off 'click', App.closeSuggest
  $('#search-suggest').hide()

App.checkImages = ->
  needsImage = []
  $('[data-check-image]').each (idx, obj) ->
    needsImage.push($(obj).data('check-image'))

  if needsImage.length > 0
    $.post '/check-images', { ids: needsImage }, (data) ->
      if data.success
        for id, image of data.images
          $img = $('[data-check-image="' + id + '"]')
          if image.url != undefined && image.size != undefined
            $img.attr('src', image.url)
            if(App.platform != 'phone')
              $img.attr('width', image.size.w)
              $img.attr('height', image.size.h)
          $img.removeAttr('data-check-image')

closeSearch = ->
  $('#nav-center').addClass('hide-small')
  return

removeHtmlStorage = (name) ->
  try
    localStorage.removeItem name
    localStorage.removeItem name + '_time'
  catch e
    return
  return

setHtmlStorage = (name, value, expires) ->
  try
    if expires == undefined or expires == 'null' or expires == null
      expires = 86400
    date = new Date
    schedule = Math.round(date.setSeconds(date.getSeconds() + expires) / 1000)
    localStorage.setItem name, value
    localStorage.setItem name + '_time', schedule
  catch e
    return
  return

statusHtmlStorage = (name) ->
  date = new Date
  current = Math.round(+date / 1000)
  try
    # Get Schedule
    stored_time = localStorage.getItem(name + '_time')
    if stored_time == undefined || stored_time == 'null' || stored_time == null
      stored_time = 0
    # Expired
    if parseInt(stored_time) < current
      # Remove
      removeHtmlStorage name
      return 0
    else
      return 1
  catch e
    return 0


checkEmail = (email, required = true) ->
  reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/
  if(!required && email == '')
    return true
  if !reg.test(email)
    return false
  return true

getUserHistory = ->
  $.ajax({
    url: '/get-user-history'
    success: (data) ->
      if data.success
        hide = true
        $history = $('#footer>.history')
        $items = $history.find('ul')
        history_array = []
        for i in data.history
          if typeof(i.img) != "undefined" && i.img != null
            element = { title: i.title, url: i.url, img: i.img.url, w: i.img.w, h: i.img.h }
            history_array.push element
            li = '<li class="small"><a href="' + element.url + '">\
            <img loading="lazy" alt="' + element.title + '" src="' + element.img + '" width="' + \
            element.w + '" height="' + element.h + '"></a></li>'
            $items.append li
            hide = false
        if hide == false
          $history.removeClass('hidden')
          setHtmlStorage('history', JSON.stringify(history_array))
  })

App.closeLightBox = ->
  $('.product-image').trigger('zoom.destroy')

App.switchTheme = (dark) ->
  if dark
    createCookie('theme', 'dark', 3650)
  else
    createCookie('theme', 'light', 3650)
  window.location.reload()
  false

App.showCookieConsent = ->
  $('.cc-banner').show()
  $('.cc-refuse').on "click", ->
    createCookie('marketing_consent', 'no', 365)
    window.location.reload()
    false
  $('.cc-accept').on "click", ->
    createCookie('marketing_consent', 'yes', 365)
    window.location.reload()
    false

App.showFullSize = (id) ->
  $('.images').each ->
    App.showOrHideControls($(this))
    return
  $('.product-image').addClass('hidden')
  $('#image-' + id).removeClass('hidden')
  if id == 0
    $('#gpreview').show()
  else
    $('#gpreview').hide()
  #$('#i-'+id).blowup()

  # App.closeLightBox()
  # $('#image-'+id).zoom({
  #   url:$('#images-url-'+id).attr('data-url'),
  #   on: 'mouseover',
  #   magnify:2,
  #   onZoomIn: ()->
  #     $('#image-'+id).width($('#image-'+id).width()+400)
  #     $('#image-'+id).height($('#image-'+id).height()+400)
  #   onZoomOut: ()->
  #     $('#image-'+id).width($('#image-'+id).width()-400)
  #     $('#image-'+id).height($('#image-'+id).height()-400)
  #   })

$  ->
  'use strict'
  reveal()
  $.extend App, {
    suggest: {
      timer: null
      query: ''
      last: ''
      focus: -1
      xhr: null }
    scroll: {
      timer: null
      last: (new Date).getTime()
      scrolling: false
      fixedMenu: false }
    id: null
    scrolled: null
  }

  App.platform = $('body').attr('class')

  $(window).scrollTop(0)
  # Vars.
  $body = $('body')
  # Disable animations/transitions until everything's loaded.
  $('.ws').removeClass 'ws'
  $body.addClass 'is-loading'
  $(window).on 'load', ->
    $body.removeClass 'is-loading'
    return
  resize_timeout = false
  resize_delay = 250
  windowResize = ->
    App.closeSuggest()
    $('#search-button').css('height', $('#search-public-list').height() + 2)
    $('.carousel.active').each ->
      if !App.isOnBlog
        App.showOrHideControls($(this))
      return
    event = new KeyboardEvent('keydown', { key: 'Escape' })
    document.dispatchEvent event
    return

  mc = readCookie('marketing_consent')
  if !mc
    App.showCookieConsent()
  else if mc == 'yes'
    if typeof gtag == 'function'
      gtag('set', { 'marketing_consent': 'yes' })

  $('#cc-prefs').on "click", ->
    App.showCookieConsent()
    false

  $('#theme-switch').on "click", ->
    App.switchTheme($(this).hasClass('theme-dark'))
  window.trpop = new Polipop('trpop', {
    closer: false
    progressbar: true
    layout: 'popups'
    insert: 'before'
    pool: 5
    sticky: true })
  window.brpop = new Polipop('trpop', {
    closer: false
    position: 'bottom-right'
    layout: 'popups'
    insert: 'before'
    pool: 5
    sticky: true })
  light = readCookie('show-light')
  if light == 'on'
    brpop.add {
      title: _('Schemă de culori')
      content: _('Pentru a schimba schema de culori\
        găsiți un link în colțul din dreapta jos al paginii.')
      type: 'default' }
    createCookie('show-light', '', -1)
  dark = readCookie('seen-dark')
  h = (new Date()).getHours()
  if dark != 'yes' && h >= 0 && h <= 4 && $('#theme-switch').hasClass('theme-dark')
    brpop.add {
      title: _('<a class="dark-side">Join the Dark Side</a>')
      content: _('We have cookies')
      type: 'default' }
    createCookie('seen-dark', 'yes', 30)
  $('body').on 'click', '.dark-side', ->
    createCookie('show-light', 'on')
    App.switchTheme(true)

  top = $(window).scrollTop()
  $(window).scrollTop(top + 1)
  $(window).scrollTop(top)
  if $('#awb').length > 0
    $('#courier').css('margin-left', $('#awb').width())
  if $('body').attr('data-page-id') == undefined
    $('body').css('min-width', 0)
    $('html').css('min-width', 0)

  window.addEventListener 'resize', ->
    clearTimeout resize_timeout
    resize_timeout = setTimeout(windowResize, resize_delay)
    return

  window.addEventListener 'scroll', ->
    App.closeSuggest()
    top = $(window).scrollTop()
    if $('aside.left').length > 0
      winH =  $( window ).height()
      footerPos = $('#footer').position()
      y = footerPos.top - top - winH
      limit = if $('.subnav').length > 1 then 113 else 75

      if !(y < 0 && top <= limit)
        if y < 0
          $('aside.left').css {
            bottom: Math.abs(y) + 'px'
            height: 'auto' }
        else
          $('aside.left').css {
            bottom: 'auto'
            height: '100%' }

        if(top > limit)
          $('aside.left').addClass 'sticky'
        else
          $('aside.left').removeClass 'sticky'
      else
        $('aside.left').removeClass 'sticky'
        $('aside.left').css {
          bottom: 'auto'
          height: '100%'
        }

    if(top > 20)
      $('#products-menu').css { display: 'none' }
      $('#products-header').show()
      $('#products-menu').css {
        top: '2.3em'
      }
      $('.jq-dropdown.jq-dropdown-tip#products-menu:after, \
      .jq-dropdown.jq-dropdown-tip#products-menu:before').css { left: '0.5em' }
    else
      $('#products-header').hide()
      $('#products-menu').css {
        position: 'absolute'
        top: '5.4em'
        left: '1em'
      }
      if($('body#home-page').length > 0)
        $('#products-menu').css { display: 'block' }

    now = (new Date).getTime()
    if now - App.scroll.last > 400 and !App.scroll.scrolling
      $(this).trigger 'scrollstart'
      App.scroll.last = now
    clearTimeout App.scroll.timer
    App.scroll.time = setTimeout((->
      if App.scroll.scrolling
        $(window).trigger 'scrollend'
      return
    ), 300)
    return

  $('.read-wrap').each (idx, obj) ->
    el = $(obj)
    wrapHeight = if el.hasClass('small-wrap') then '140px' else '410px'
    height = el.height()
    text = el.find('.read-text')
    img = el.find('img.cover')
    if text.length == 1
      pos = text.position()
      textHeight = text.height()
      more = el.find('.read-more')
      less = el.find('.read-less')
      if textHeight + pos.top > height
        more.show()
        more.on 'click', ->
          img.css { 'max-height': 99999 }
          textHeight = text.height()
          el.css { 'height': textHeight + pos.top + 50 + 'px', 'max-height': 99999 }
          more.hide()
          less.show()
          false
        less.on 'click', ->
          img.css { 'max-height': wrapHeight }
          el.css { 'height': wrapHeight }, 200
          more.show()
          less.hide()
          false
    return

  $('#show-search').on 'click', ->
    $('#nav-center').toggleClass('hide-small')
    $('#search').focus()
    false

  $('#search').on 'keyup', (event) ->
    val = $('#search').val()
    if event.keyCode == 27 || val == '' #Esc or empty
      App.closeSuggest()
      return
    if event.keyCode == 13
      App.closeSuggest()
      return
    move = 0
    if event.keyCode == 40 #down arrow
      move = 1
    if event.keyCode == 38 #up arrow
      move = -1
    if move != 0
      items = $('#search-suggest a')
      newFocus = App.suggest.focus + move
      if newFocus == -1
        $('#search').val(App.suggest.query)
      else if newFocus >= 0 && newFocus <= items.length - 1
        $item = $(items[newFocus])
        $('#search').val($item.text())
        if App.suggest.focus >= 0
          $(items[App.suggest.focus]).removeClass('focused')
        $item.addClass('focused')
        App.suggest.focus = newFocus
      return
    if App.suggest.query != val && $( window ).width() > 320
      App.scheduleSuggest(val)
    return

  $('body').on 'click', '.cancel', ->
    $(this).closest('.box').trigger('close')
    return
  $('body').on 'click', '#add-list', App.openCreateList
  $('body').on 'click', '#list-new-create', ->
    $('#list-new-message').hide()
    $form = $(this).parent().prev()
    data = { title: $form.find('input[name=title]').val(), \
    is_public: $form.find('input[name=is_public]:checked').val() }
    ean13 = $form.attr('data-id')
    if(ean13 != undefined && ean13 != null && ean13.length == 13)
      data.ean13 = ean13
    if !data.title
      $('#list-new-message').html("Te rugăm să introduci titlul listei").show()
      return
    $.post '/user/new-list', data, (json) ->
      if json.success
        window.location.reload()
      else
        if json.message
          $('#list-new-message').html(json.message).show()
    return

  $('body').on 'click', '.tabs-bar>li', App.changeTab

  App.checkImages()

  $('#close-search').on 'click', ->
    closeSearch()
    false

  initResponsiveTable($('table.responsive'))
  $('.xprlist h4 a').dotdotdot { watch: 'window' }
  $('#search-button').css('height', $('#search-public-list').height() + 2)

 # window.onmouseover = (e) ->

 #    return
  if $.isTouchCapable()
    if $('#products').length > 0
      $('#products').on 'click', ->
        $('#products').jqDropdown 'show'
        false
    if $('#lists-data').length > 0
      $('#show-lists').on 'click', ->
        $('#show-lists').jqDropdown 'show'
        false
    if $('#user-data').length > 0
      $('#show-user').on 'click', ->
        $('#show-user').jqDropdown 'show'
        false
    if $('#cart-data').length > 0
      $('#show-cart').on 'click ', ->
        $('#show-cart').jqDropdown 'show'
        false
  menu = $('#products-menu>ul')
  menu.menuAim {
    activate: activateSubmenu
    deactivate: deactivateSubmenu
    exitMenu: exitMenu }

  $('#products-menu').on 'mouseleave', ->
    $('#products').jqDropdown 'hide'
    return
  $('[id^=prod-]').on 'mouseleave', ->
    $('.prod-submenu').hide()
    return

  $('#user-data').on 'show', ->
    App.toggleUserVisibility()

  $('#cart-data').on 'show', ->
    App.toggleUserVisibility()
    $('.cart-loading').show()
    $.get 'https://' + window.location.host + '/cart.json?simple=on', (json) ->
      #$('.cart-loading').hide()
      html = ''
      total = 0
      qty = 0
      for book in json.items
        qty = qty + 1
      if(qty > 0)
        for book in json.items
          if book.img != undefined
            html += '<li>' + '<div class="cover"><a href="' + book.url + '"><img src="' +
                      book.img.url.replace('$', 'bx') + '" width="' + book.img.w +
                      '" height="' + book.img.h + '" /></a></div>' +
                      '<div class="title"><a href="' + book.url + '">' + book.title +
                      '</a></div> <div class="price">' + 'x' + book.quantity +
                      '<br />' + formatPrice(book.quantity * book.price) + '</div>' + '</li>'
          total += book.quantity * book.price
        $('#cart-data > ul').html(html)
        $('#cart-data > ul').find('div.title').dotdotdot()
        $('#cart-total-value').html(formatPrice(total))
      else
        html += '<li>'+
                  '<br />'+
                  '<div class="align-center"><h2>Coșul de cumpăraturi este gol</h2></div>'+
                '</li>'
        $('#cart-data > ul').html(html)

  $('#lists-data').on 'show', ->
    App.toggleUserVisibility()
    if App.is_logged
      $.get '/user/lists.json?limit=5', (json) ->
        $('.user-list').remove()
        $('.lists-loading').hide()
        html = ''
        html += '<li class="user-list"><a href="/user/list/' + item.id + '">' \
        + item.title + '</a></li>' for item in json
        $(html).insertAfter('.user-lists-sep')

  $('#show-user').on 'click', ->
    $('#show-user').jqDropdown('show')
    return

  $('.select-wrapper').each ->
    el = $(this)
    text = el.find('select>option').filterSelected().text()
    el.find('span').text text
    return
  $('body').on 'change', '.select-wrapper>select', ->
    el = $(this)
    el.siblings('span').text el.find('option').filterSelected().text()
    return

  $('body').on 'click', '.add2cart', ->
    el = $(this)
    ean = el.data('ean') or $('body').data('page-id')
    if ean
      $.post '/cart/add', {
        ean: ean
        qty: 1
      }, ((data) ->
        $(window).trigger 'added2cart', data
        if data.success
          gtag('event', 'add_to_cart', {
            'currency': 'RON'
            'value': data.item.price
            'items': [{
              'item_id': data.ean,
              'item_name': data.item.title
              'item_brand': data.item.publisher
              'item_category': data.item.category
              'item_variant': data.item.form
              'price': data.item.price
            }]
            event_callback: () ->
              window.location.href = '/cart/added/' + data.ean
            event_timeout: 3000
          })
        else
          trpop.add {
            title: _('Stoc insuficient')
            content: data.error
            type: 'warning' }
        return
      )
    false

  $('body').on 'click', '.carousel-add2cart', ->
    el = $(this)
    ean = el.data('ean')
    title = el.data('title')
    if ean
      $.post '/cart/add', { ean: ean, qty: 1 }, (json) ->
        base_number = json.total_value.toString().split(".")[0]
        after_floating_point = json.total_value.toString().split(".")[1]
        if json.success
          gtag('event', 'add_to_cart', {
            'currency': 'RON'
            'value': json.item.price
            'items': [{
              'item_id': json.ean,
              'item_name': json.item.title
              'item_brand': json.item.publisher
              'item_category': json.item.category
              'item_variant': json.item.form
              'price': json.item.price
            }]
          })
          update_message = '<b>' + title + '</b> a fost adăugat în coș. Acum aveți în coș ' + \
          json.totals.count + ' în valoare de ' + parseInt(base_number) + \
          '<sup><span class="hidden">.</span>' + parseInt(after_floating_point) + '</sup> lei'
          $('#cart-added-text').html(update_message)
          if App.platform == 'phone'
            message = _('Produsul <span style="font-weight:bolder;">' + title + \
            '</span> a fost adăugat în coș' + '<br/><a href="/cart" class="button full icon">\
            <svg class="ico"><use href="/icons.svg#ico-cart-plus"/></svg>Finalizare comandă</a>')
          else
            message = _('Produsul <span style="font-weight:bolder;">' + title + \
            '</span> a fost adăugat în coș')
          trpop.add {
            title: _('Produs')
            content: message
            type: 'notice' }
        else
          trpop.add {
            title: _('Produs')
            content: json.error
            type: 'warning' }
        return
    false

  $('#msgclose').on 'click', (event) ->
    $('.submessage').hide()
    createCookie('submessage-read', 'Y')
    return false


  $('body').on 'click', '.add2list-btn', ->
    el = $(this)
    ean = el.data('ean') or $('body').data('page-id')
    if el.attr('href') != '#'
      return true
    App.add2list el.data('type'), ean
    if el.data('type') == 'R'
      loadInLightbox({
        url: '/write-review/' + ean
        style: 'max-width: 45em'
        centered: true
        onLoad: ->
          App.initializeRating()
      })
    return false

  $('#footer-subscribe').on 'click', App.subscribe
  $('#footer-email').on 'keydown', (event) ->
    if event.keyCode == 13
      App.subscribe

  if $('.carousel').length > 0
    App.initCarousel()

  $('.deleteSubject').on 'click', ->
    code = $(this).attr('data-code')
    $container = $('#carousel-' + code)
    $container.detach()
    $.post '/delete-category', { code }, (json) ->
      if json.success
        trpop.add {
          title: _('Categorie')
          content: json.message
          type: 'notice' }
      else
        trpop.add {
          title: _('Categorie')
          content: json.message
          type: 'warning' }
      return
    false

  open = $('#upload-images').attr('data-open')
  if open == '1'
    $('#upload-images').trigger('click')



  if App.is_logged
    try
      path = window.location.pathname
      if typeof(Storage) != undefined && typeof(localStorage) != undefined \
      && localStorage != undefined && path.indexOf('cart') == -1 && path.indexOf('pos') == -1
        history = localStorage.getItem('history')
        $history = $('#footer>.history')
        $items = $history.find('ul')
        if !history || history == 'null' || history == null || statusHtmlStorage('history') == 0
          getUserHistory()
        else
          history = JSON.parse(history)
          temp = []
          for i in history
            duplicate = false
            for j in temp
              if i.url == j.url
                duplicate = true
            if duplicate == false
              temp.push i
          history = temp
          for i in history
            li = '<li class="small"><a href="' + i.url + '"><img loading="lazy" alt="' + i.title + \
            '" src="' + i.img + '" width="' + i.w + '" height="' + i.h + '"></a></li>'
            $items.append li
          $history.removeClass('hidden')
    catch e
      getUserHistory()
  else
    removeHtmlStorage 'history'
    $('#footer>.history').addClass 'hidden'

  # Nav.
  $nav = $('#nav')
  $navToggle = $('a[href="#nav"]')
  # Event: Prevent clicks/taps inside the nav from bubbling.

  $nav.on 'click', (event) ->
    if $(event.target).prop('href')
      window.location.href = $(event.target).prop('href')
    event.stopPropagation()
    return

  $nav.on 'touchend', (event) ->
    App.closeSuggest()
    event.stopPropagation()
    return

  # Event: Hide nav on body click/tap.
  $('body').on 'click touchend', (event) ->
    #App.closeSuggest()
    if event.target.nodeName == 'svg' && \
    $(event.target).find('use').attr('href') == '/icons.svg#ico-cancel'
      return
    if $nav.hasClass('slide-out')
      $nav.attr 'class', 'slide-in'
      $navToggle.find('use').attr 'href', '/icons.svg#ico-menu'
    if $(event.target).parents('.jq-dropdown').length == 0
      $('[data-jq-dropdown]').jqDropdown 'hide'
    return
  # Toggle.
  # Event: Toggle nav on click.
  $navToggle.on 'click', (event) ->
    App.closeSuggest()
    if $nav.hasClass('slide-out')
      $nav.attr 'class', 'slide-in'
      $navToggle.find('use').attr 'href', '/icons.svg#ico-menu'
    else
      $nav.attr 'class', 'slide-out'
      $navToggle.find('use').attr 'href', '/icons.svg#ico-cancel'
    event.preventDefault()
    event.stopPropagation()
    return
  # Event: Hide on ESC.
  $(window).on 'keydown', (event) ->
    if event.keyCode == 27
      $nav.removeClass 'visible'
    return
  return